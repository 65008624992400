// Basics
import React from 'react';
import './index.scss';
import { Link, StaticQuery, graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react'
import { get, map } from 'lodash';

// Views
import AtomAnimation from '../../Atoms/Animation';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

class MoleculeFooter extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-content">
          <Grid columns={3} stackable>

            <StaticQuery
              query={graphql`
              query {
                wordpressAcfOptions {
                  options {
                    global_templates_header_and_footer {
                      footer {
                        left_section_content {
                          section_content
                          section_heading
                        }
                        middle_section_content {
                          section_heading
                          section_links {
                            link_classes
                            link_text
                            link_url
                            open_in_new_tab
                          }
                        }
                        right_section_content {
                          subscribe_section {
                            section_content
                            section_heading
                          }
                        }
                      }
                    }
                  }
                }
              }
              
        `}
              render={data => {
                const footerData = get(data, 'wordpressAcfOptions.options.global_templates_header_and_footer.footer');
                return (
                  <Grid.Row>
                    <Grid.Column>
                      <AtomAnimation>
                        <div className="footer-widget">
                          <h5>{get(footerData, 'left_section_content.section_heading')}</h5>
                          <div className="small-text">
                            <HtmlParser html={get(footerData, 'left_section_content.section_content')} />
                          </div>
                        </div>
                      </AtomAnimation>

                    </Grid.Column>
                    <Grid.Column>
                      <AtomAnimation>
                        <div className="footer-widget">
                          <h5>{get(footerData, 'middle_section_content.section_heading')}</h5>
                          <ul>
                            {
                              map(get(footerData, 'middle_section_content.section_links'), (row, index) => {
                                return (
                                  <li key={index}>
                                    <Link to={get(row, 'link_url')}>{get(row, 'link_text')}</Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </AtomAnimation>
                    </Grid.Column>
                    <Grid.Column>
                      <AtomAnimation>
                        <div className="footer-widget">
                          <h5>{get(footerData, 'right_section_content.subscribe_section.section_heading')}</h5>
                          <div className="small-text">
                            <HtmlParser html={get(footerData, 'right_section_content.subscribe_section.section_content')} />
                          </div>
                        </div>
                      </AtomAnimation>
                    </Grid.Column>
                  </Grid.Row>
                )
              }}
            />


          </Grid>
        </div>

        <div className="copyright">
          <p>
            <span>Copyright &copy; 2018</span>
            <span>
              {' '}
              <Link to="/" className="link">AppInfini Technologies Pvt. Ltd.</Link>
              {' '}
            </span>
            <span> All Rights Reserved.</span>
          </p>
        </div>
      </div>
    )
  }
}

export default MoleculeFooter;
