// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

// Libraries
import { get } from 'lodash';
import HtmlParser from '../../../libraries/HtmlParser';

// Utilities

// View
import MoleculeSectionInfo from '../../Molecules/SectionInfo';
import AtomImageBackground from '../../Atoms/ImageBackground';

class OrganismBanner extends React.Component {

  render() {
    const { backgroundImage, className, description, title } = this.props;

    return (
      <div
        className={`organism-banner ${className}`}
      >
        <AtomImageBackground
          source={get(backgroundImage, 'localFile.childImageSharp.fluid')}
          type="fluid"
        />
        <Container>
          <div className="banner-content">
            <MoleculeSectionInfo
              className="text-color-gray"
              title={title}
              description={<HtmlParser html={description} />}
            />
          </div>
        </Container>
      </div>
    )
  }
}

// OrganismBanner.defaultProps = {
//   backgroundImage: BannerImage,
//   className: '',
//   description: (
//     <p>At AppInfini, we specialize in creating customized digital solutions for growing businesses.Our experts can help design digital solutions that create lasting impressions for your startup!</p>
//   ),
//   //title: "Let’s accelerate the growth of your startup!",

// };

OrganismBanner.propTypes = {
  backgroundImage: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  title: PropTypes.string.isRequired,
}

export default OrganismBanner;

