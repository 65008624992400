// Basics
import React from 'react';
import Reveal from 'react-reveal/Reveal';
import PropTypes from 'prop-types';

function AtomAnimation({
  children,
  effect,
  isVisible,
  ...rest
}) {

  return (
    <Reveal
      effect={effect}
      appear={isVisible}
      // when={isVisible}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Reveal>
  );
}

AtomAnimation.defaultProps = {
  effect: 'fadeInUp',
  isVisible: true
};

AtomAnimation.propTypes = {
  effect: PropTypes.string,
  isVisible: PropTypes.bool
};

export default AtomAnimation;
