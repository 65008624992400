// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

class OrganismMainContentWrapper extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="organism-main-content-wrapper">
        {children}
      </div>
    )
  }
}

OrganismMainContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrganismMainContentWrapper;
