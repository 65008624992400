/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

// Views
import AtomAnimation from '../../Atoms/Animation';

class MoleculeSectionInfo extends React.Component {
  render() {
    const { className, content, description, title } = this.props;

    return (
      <AtomAnimation>
        <div className={`molecule-section-info ${className}`}>
          <div className="info-head">
            {
              title &&
              <h2>{title}</h2>
            }
            {
              description &&
              <div className="description"> {description} </div>
            }
          </div>
          <div className="info-content">
            {content}
          </div>

        </div>
      </AtomAnimation>
    )
  }
}

MoleculeSectionInfo.defaultProps = {
  className: ''
};

MoleculeSectionInfo.propTypes = {
  className: PropTypes.string,
  content: PropTypes.element,
  description: PropTypes.element,
  title: PropTypes.string,
};

export default MoleculeSectionInfo;

